import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import BannerRenderer from 'common/BannerRenderer';
import BodyRenderer from 'common/BodyRenderer';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Breadcrumbs from 'components/Breadcrumbs';

import { GenericPageTypes } from './models';

import 'styles/main.scss';
import './GenericPage.scss';

const GenericPage: FC<GenericPageTypes.GenericPageType> = ({ data: { genericPage } }) => {
  const { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs } =
    genericPage.seo;
  const { title, level, banner, modules, url, lang } = genericPage;

  return (
    <Layout headerTransparent>
      <Seo
        {...{
          seo: genericPage.seo,
          openGraph: genericPage.openGraph,
          pageTitle: title,
          seoExternalHreflangs,
          pageUrl: url,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <div className="banners">
        <Breadcrumbs level={level} />
        {banner && <BannerRenderer {...banner[0]} />}
      </div>
      <div id="modules">
        <BodyRenderer modules={modules} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    genericPage(url: { eq: $url }) {
      pageId
      level
      lang
      url
      seo {
        ...SEOStructureFragment
      }
      openGraph {
        ...OpenGraphFragment
      }
      title
      banner {
        ...FragmentBanners
      }
      modules {
        ...FragmentModules
      }
    }
  }
`;

export default GenericPage;
